import Strapi from './Strapi';

const collection = 'system-configs';

let cache;

const getSystemConfig = async (clearCache = false) => {
    if (!clearCache && cache) return cache;

    const result = await Strapi.get(`${collection}`);
    cache = result;
    return result;
}

const getSystemConfigByKey = async (key, clearCache = false) => {
    if (!clearCache && cache) return cache.find(item => item.key === key);

    const result = await Strapi.get(`${collection}`);
    cache = result;
    return result.find(item => item.key === key);
}


export { getSystemConfig, getSystemConfigByKey };
