import Strapi from './Strapi';
import qs from 'qs';

const collection = 'unit-conversions';

const getUnitConversion = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const createUnitConversion = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editUnitConversion = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const batchEditUnitConversion = (body) => Strapi.put(`${collection}/batch`, JSON.stringify(body));
const delUnitConversion = (id) => Strapi.delete(`${collection}/${id}`);

export { getUnitConversion, createUnitConversion, delUnitConversion, editUnitConversion, batchEditUnitConversion };
