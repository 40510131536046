import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../localization/i18n';

function AccessibleNavigationAnnouncer() {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // ignore the /
    if (location.pathname.slice(1)) {
      // make sure navigation has occurred and screen reader is ready
      setTimeout(() => setMessage(`${t("Navigated to")} ${location.pathname.slice(1)} ${t("page.")}`), 500);
    } else {
      setMessage('');
    }
  }, [location]);

  return (
    
    <span className="sr-only" role="status" aria-live="polite" aria-atomic="true">
      {message}
    </span>
  );
}

export default AccessibleNavigationAnnouncer;
