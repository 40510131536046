import Strapi from './Strapi';
import qs from 'qs';

const collection = 'dashboards';

const getMiniChartData = (companyId, parent, year) => Strapi.get(`${collection}/mini-chart?${qs.stringify({
  companyId, parent, year
})}`);

const getOutcomeByIndicator = (companyId, indicatorId, masterSiteAndGroup, year) => {
  let url = `${collection}/calculation/${companyId}/${indicatorId}?parent=${masterSiteAndGroup}`;
  if (year) url += `&year=${year}`;
  return Strapi.get(url);
}

export {
  getMiniChartData,
  getOutcomeByIndicator,
};
