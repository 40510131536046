import Strapi from './Strapi';
import qs from 'qs';

const collection = 'translations';

const getTranslations = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const createTranslations = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editTranslations = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const batchEditTranslations = (body) => Strapi.put(`${collection}/batch`, JSON.stringify(body));
const delTranslations = (id) => Strapi.delete(`${collection}/${id}`);

export { getTranslations, createTranslations, delTranslations, editTranslations, batchEditTranslations };
