const url = process.env.REACT_APP_API_URL;

async function getPlans() {
  try {
    const response = await fetch(`${url}/plans`, {
      headers: {
        Authorization: `bearer ${localStorage.jwt}`,
      },
    });

    if (response.status >= 400) throw response;
    const result = await response.json();
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function createPlan(body) {
  try {
    const response = await fetch(`${url}/plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status >= 400) throw response;
    const result = await response.json();
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function editPlan(id, body) {
  try {
    const response = await fetch(`${url}/plans/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status >= 400) throw response;
    const result = await response.json();
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function delPlan(id) {
  try {
    const response = await fetch(`${url}/plans/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
    });

    if (response.status >= 400) throw response;
    const result = await response.json();
    return result;
  } catch (e) {
    console.log('Del company error error', e);
    throw e;
  }
}

export { getPlans, createPlan, delPlan, editPlan };
