import Strapi from './Strapi';
import qs from 'qs';

const authCollection = 'auth/local';
const passwordCollection = 'password';
const userCollection = 'users';

const getUsers = (query) => Strapi.get(`${userCollection}?${qs.stringify(query)}`);
const getUsersCount = (query) => Strapi.get(`${userCollection}/count?${qs.stringify(query)}`);
const getUsersByCompanyId = (id) => Strapi.get(`${userCollection}?company=${id}&_limit=9999`);
const editUsers = (id, body) => Strapi.put(`${userCollection}/${id}`, JSON.stringify(body));
const forgetPassword = (email) => Strapi.post(`auth/forgot-password`, JSON.stringify({ email }))
const resetPasswordByCode = (code, password, passwordConfirmation) => 
  Strapi.post(`auth/reset-password`, JSON.stringify({ code, password, passwordConfirmation }))

function getCurrentUserInfo() {
  try {
    let user = JSON.parse(localStorage.user);
    return user;
  } catch (e) {
    // console.log(e);
    return null;
  }
}

const login = async (username, password) => {
  const body = { identifier: username, password };
  const result = await Strapi.post(`${authCollection}`, JSON.stringify(body));

  localStorage.setItem('jwt', result.jwt);
  localStorage.setItem('user', JSON.stringify(result.user));

  await editUsers(result.user.id, { lastLogin: new Date() });

  return result.user;
};

const createUser = async (body) => {
  const result = await Strapi.post(`${authCollection}/register`, JSON.stringify(body));

  /*
  * As registration doesn't update the role and password
  * need to run by separate call
  */
  const userId = result.user.id;
  if (userId && body.role) {
    await Strapi.put(
      `${userCollection}/${userId}`,
      JSON.stringify({ role: body.role, password: body.password })
    );
  }

  return result;
};

const updateUser = async (userId, body) => {
  return await Strapi.put(
    `${userCollection}/${userId}`,
    JSON.stringify(body)
  );
}

const deleteUser = async (userId) => {
  return await Strapi.delete(`${userCollection}/${userId}`);
}

const resetPassword = (body) => Strapi.post(`${passwordCollection}/reset`, JSON.stringify(body));

async function logout() {
  localStorage.removeItem('jwt');
  localStorage.removeItem('user');
  localStorage.removeItem('site_group');
  window.location.href = '/login';
}

const resendWelcomeEmail = (id) => Strapi.post(`${passwordCollection}/welcome/${id}`);

export {
  login,
  logout,
  createUser,
  updateUser,
  deleteUser,
  getUsers,
  getUsersCount,
  getUsersByCompanyId,
  editUsers,
  getCurrentUserInfo,
  resetPassword,
  forgetPassword,
  resetPasswordByCode,
  resendWelcomeEmail,
};
