import Strapi from './Strapi';
import qs from 'qs';

const collection = 'constants';

const getConstant = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const createConstant = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editConstant = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const batchEditConstant = (body) => Strapi.put(`${collection}/batch`, JSON.stringify(body));
const delConstant = (id) => Strapi.delete(`${collection}/${id}`);

export { getConstant, createConstant, delConstant, editConstant, batchEditConstant };
