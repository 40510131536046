import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/tailwind.output.css';
import './assets/css/datasheet.css';
import './assets/css/custom.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import aecTheme from './assets/theme'
import * as serviceWorker from './serviceWorker';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/AlertTemplate';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
  <I18nextProvider i18n={i18next}>
    <AlertProvider template={AlertTemplate} timeout={60000}>
      <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill theme={aecTheme}>
            <App />
          </Windmill>
        </Suspense>
      </SidebarProvider>
    </AlertProvider>
  </I18nextProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
